:root, html[data-theme='default'] {
  --fill-color-selected: #DB874B; /*raw sienna */
  --stroke-color-selected: coral;
  --fill-color: #E29F6F; /* tumbleweed */
  --stroke-color: grey;
  --fill-color-word: rgb( 149, 178, 143);
  --game-background: #C4D7F4; /* hawkes blue */
  --game-background2: #DBAB56;
}

html[data-theme='theme-valentine'] {
  --fill-color-selected: #e544e5; 
  --stroke-color-selected: coral;
  --fill-color: #ff4cff;  
  --stroke-color: grey;
  --fill-color-word: #84abfc;
  --game-background: #ea8681; 
  --game-background2: #ff76dc;
}


html[data-theme='theme-teal'] {
  --fill-color-selected: #DB874B; /*raw sienna */
  --stroke-color-selected: coral;
  --fill-color: #E29F6F;  /* tumbleweed */
  --stroke-color: grey;
  --fill-color-word: #90AEAF;
  --game-background: #7DBDBD; /* glacier teal */
}

html[data-theme='theme-tan'] {
  --fill-color-selected: #768A9E; /* light slate grey */
  --stroke-color-selected: coral;
  --fill-color: #90AEAF;  /* tan  */
  --stroke-color: grey;
  --fill-color-word: #988558; /* silver from medal */
  --game-background:  #CDB591; /* tan */
}

html[data-theme='theme-turtledove'] {
  --fill-color-selected: rgb(166,181,188); 
  --stroke-color-selected: coral;
  --fill-color: rgb(191,215,208);  
  --stroke-color: grey;
  --fill-color-word: rgb(127,115,148); 
  --game-background:  rgb(217,220,212); 
}

html[data-theme='theme-thanksgiving'] {
  --fill-color-selected:  #C68E23; 
  --stroke-color-selected: coral;
  --fill-color: #F8B12C;  
  --stroke-color: grey;
  --fill-color-word: #996236; 
  --game-background:  #ED732E; 
}

html[data-theme='theme-holiday'] {
  --fill-color-selected: #189AD3;
  --stroke-color-selected: coral;
  --fill-color: #6BBEE3;
  --stroke-color: grey;
  --fill-color-word: #c0c0c0;
  --game-background: #C4D7F4; 
  --game-background2: #C0C0C0;
}

html[data-theme="bogus"] {
  --fill-color-word: #DBAB56; /* gold from medal */
  --fill-color-word: #D89E77; /* bronze from medal */  
}

@keyframes flakemove {
  0% {
    transform: translateY(0px) rotateY(0deg);
  }
  100% {
    transform: translateY(800px) rotateY(360deg);
  }
}

.mover{
  height: 100px;
  width: 100px;
  transition: 0.5s;
  animation: flakemove 4s infinite;
} 


.App {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  touch-action: none;
  text-align: center;
  height: 100dvh; /*height: 100vh;*/
  width: 100dvw; /*width: 100vw;*/
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.App-header {
  background-color:#282c34;
  font-size: 18px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.game-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: lightgrey;
}

.main {
  background-image: linear-gradient(to bottom right, var(--game-background), var(--game-background2));
  width: 480px;
  font-family: Arial, Helvetica, sans-serif;
  border:3px solid black;
}

.scoreContainerWrapper {
  display: inline-block;
  margin-top: 15px;
  width: 300px;
  height: 60px;
}

.scoreContainer {
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  touch-action: none;
}

.score {
  border: 4px solid rgb(17,100, 117);
  background: rgb( 236, 236, 236);
  margin: 3px 12px 2px 0px;
  font-size: 12px;
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 2px 2px 5px grey;

}

.score span {
  font-size: 24px;
  font-weight: bold;
}

.scoreMedal {
  border: 4px solid rgb(17,100, 117);
  background: rgb( 236, 236, 236);
  margin: 3px 0px 2px 4px;
  font-size: 12px;
  width: 205px;
  height: 50px;
  position: relative;
  box-shadow: 2px 2px 5px grey;
}

.scoreMedal span {
  font-size: 24px;
  font-weight: bold;
}

.play {
  margin: 8px 15px 8px 15px;
  font-size: 14px;
  position: relative;
}

.gameGrid {
  margin-top: 15px;
  margin-bottom: 0px;
  display: inline-grid;
  position: relative;
  gap: 0px;
  grid-template-columns: repeat(5, 1fr);
  border: 5px solid rgb(17,100, 117);   /*nightbluecolor*/
  box-shadow: 5px 5px 5px grey;
}

.gameGrid div {
  display: inline-block;
  background: rgb( 236, 236, 236);
}

.pileGrid {
  margin-top: 0px;
  margin-bottom: 20px;
  display: inline-grid;
  position: relative;
  gap: 0px;
  grid-template-columns: repeat(5, 1fr);
  border: 2px solid rgb(17,100, 117);   /*nightbluecolor*/
  box-shadow: 5px 5px 5px grey;
}

.pileGrid div {
  display: inline-block;
  background: rgb( 236, 236, 236);
}

.toast {
  font-size: 8;
  position: absolute;
  width: 200px;
  height: 40px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  text-align: center;
  line-height: 35px;
  color: black;
  z-index: 901;
  left: 120px;
  top: 210px;
}

.modal {
  display: none;
  position: fixed;
  z-index:901;
  touch-action: none;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.modalContent {
  /*filter: drop-shadow(4px 4px 1px white);*/
  box-shadow: 4 4 30px white;
  left: 50%;
  top: 70px;
  margin-left: -175px;
  touch-action: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  position: relative; 
  height:  auto;
  width: 350px;
  border-radius: 10px;
  background-color: white;
  z-index: 902;
  -webkit-appearance: none;
}

@keyframes celebrateFrames {
  from {opacity:0;}
  to {opacity: 1;}
}

@keyframes celebrateFramesReverse {
  from {opacity:1;}
  to {opacity: 0;}
}


.celebrate {
  position: absolute;
  left:calc(50% - 100px);
  width: 200px;
  height: 200px;
  text-align: center;
  /*transition: font-size 2s;*/
  animation-name: celebrateFrames;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  z-index:910;
}

.tooltip {
  z-index:910;
}

.checkboxgroup {
  display: inline-block;
  text-align: center;
}
.checkboxgroup label {
  display: block;
}

.tile {
  -webkit-user-drag: element;
  -khtml-user-drag: element;
}

.tileSelected {
  fill: var(--fill-color-selected);
  stroke: var(--stroke-color-selected);
}

.tileNotSelected {
  fill: var(--fill-color);
  stroke: var(--stroke-color);
}

.tileWord {
  fill: var(--fill-color-word);
  stroke: var(--stroke-color);
}


header,
footer{
  background-color: #ccc;
  flex-basis: 50px;
  
  /*ie10*/
  -ms-flex-preferred-size: 50px;
  
  /*center align of links*/
  display: flex;
  align-items: center; /*vertical*/
  justify-content: space-around; /*or space-between*/
  /*ie10*/
  display: -ms-flexbox;
  -ms-flex-align: center; /*vertical*/
  -ms-flex-line-pack: distribute; /*or justify*/
}

footer {
  /*ie10 short content*/
  margin-top: auto; 
}
header a{
  /*ie10*/ 
  /*hack to trigger the layout for horizontal alignment distribute/justify*/
  display: inline-block;
}
/* fixed header and footer with scrollable main section */
.fixed-hf .main{
  overflow-y: auto;
}

/*
background-image: linear-gradient(#42A1EC, #0070C9);
border: 1px solid #0077CC;
*/
.button {
  background-image: linear-gradient(#ECA142, #C97000);
  border: 1px solid #C97000;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -.022em;
  line-height: 1.47059;
  min-width: 30px;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button:disabled {
  cursor: default;
  opacity: .3;
}

/*
background-image: linear-gradient(#51A9EE, #147BCD);
border-color: #1482D0;
*/

/*
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
*/
.button:active {
  background-image: linear-gradient(#D9943D, #B96700);
  border-color: #BC6D00;
  outline: none;
}

/*  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;*/
.button:focus {
  box-shadow: rgba(253, 192, 131, 0.5) 0 0 0 3px;
  outline: none;
}

.playbutton {
  cursor: pointer;
  box-shadow: 3px 3px 3px grey;
}

@media (hover: hover) {
  .button:hover {
    background-image: linear-gradient(#EEA951, #CD7B14);
    border-color: #D08214;
    text-decoration: none;
  }
  
  .playbutton:hover {
    box-shadow: rgba(253, 192, 131, 0.5) 0 0 0 3px;
  }
}
